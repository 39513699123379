import FormGroup from "../../components/FormGroup";
import { client } from "./client";
import * as yup from "yup";
import {
  FormAsyncSelect,
  FormAsyncSelectProps,
} from "../../components/FormSelect";
import { ComponentType } from "react";
import { AADAppJobs, AADApps, AADGroups, Json } from "../api-types";
import type OptionData from "../server/models/OptionData";
import type Grantor from "../server/models/Grantor";
import type DirectoryObject from "../server/models/DirectoryObject";
import type { Option } from "../server/models/Param";
import FormTextArea from "../../components/FormTextArea";

export interface Action {
  name: string;
  schema: yup.ObjectSchema<Json<OptionData>>;
  Controls: ComponentType<{
    grantor: Json<Grantor>;
    prefix: string;
    values: Json<Option>;
  }>;
}

export interface ClientGrantor {
  name: string;
  schema: yup.ObjectSchema<Json<Grantor>>;
  Controls: ComponentType<{ grantor: Json<Grantor> }>;
  defaults: Json<Grantor>;
  actions: { [key: string]: Action };
  disableParams?: boolean;
}

const grantors: { [key: string]: ClientGrantor } = {
  aad: {
    name: "Azure AD SSO",
    schema: yup.object({
      app: yup.object().nullable(),
      job: yup.string().nullable(),
    }),
    Controls({ grantor }) {
      return (
        <>
          <FormGroup<
            FormAsyncSelectProps<Json<DirectoryObject>, Json<DirectoryObject>>
          >
            label="SSO application"
            as={FormAsyncSelect}
            name="grantor.app"
            isClearable
            loadOptions={(inputValue: string) =>
              client<Json<AADApps>>("json")(
                `/api/self-service/aad/apps?query=${inputValue}`
              )
            }
            getOption={(x) => x}
            getOptionValue={(x) => x.id ?? ""}
            getOptionLabel={(x) => x.name ?? ""}
            getFormValue={(x) => x}
          />
          {grantor.app && (
            <FormGroup<FormAsyncSelectProps<Json<DirectoryObject>, string>>
              label="SSO provisioning job"
              as={FormAsyncSelect}
              name="grantor.job"
              isClearable
              isSearchable={false}
              defaultOptions
              loadOptions={() =>
                client<Json<AADAppJobs>>("json")(
                  `/api/self-service/aad/apps/${grantor.app?.id}/jobs`
                )
              }
              getOption={(x: string | null) => (x ? { id: x } : null)}
              getOptionValue={(x) => x.id ?? ""}
              getOptionLabel={(x) => x.id ?? ""}
            />
          )}
        </>
      );
    },
    defaults: {},
    actions: {
      aadProvisioning: {
        name: "Re-provision AAD Application",
        schema: yup.object({
          app: yup.object().nullable(),
          job: yup.string().nullable(),
        }),
        Controls({ prefix, values }) {
          return (
            <>
              <FormGroup<
                FormAsyncSelectProps<
                  Json<DirectoryObject>,
                  Json<DirectoryObject>
                >
              >
                label="SSO application"
                as={FormAsyncSelect}
                name={`${prefix}.data.app`}
                isClearable
                loadOptions={(inputValue: string) =>
                  client<Json<AADApps>>("json")(
                    `/api/self-service/aad/apps?query=${inputValue}`
                  )
                }
                getOption={(x) => x}
                getOptionValue={(x) => x.id ?? ""}
                getOptionLabel={(x) => x.name ?? ""}
                getFormValue={(x) => x}
              />
              {values.data?.app && (
                <FormGroup<FormAsyncSelectProps<Json<DirectoryObject>, string>>
                  label="SSO provisioning job"
                  as={FormAsyncSelect}
                  name={`${prefix}.data.job`}
                  isClearable
                  isSearchable={false}
                  defaultOptions
                  loadOptions={() =>
                    client<Json<AADAppJobs>>("json")(
                      `/api/self-service/aad/apps/${values.data?.app?.id}/jobs`
                    )
                  }
                  getOption={(x: string | null) => (x ? { id: x } : null)}
                  getOptionValue={(x) => x.id ?? ""}
                  getOptionLabel={(x) => x.id ?? ""}
                />
              )}
            </>
          );
        },
      },
      aadGroup: {
        name: "Add to AAD group",
        schema: yup.object({
          group: yup.object(),
        }),
        Controls({ prefix }) {
          return (
            <FormGroup<
              FormAsyncSelectProps<Json<DirectoryObject>, Json<DirectoryObject>>
            >
              label="SSO Group"
              as={FormAsyncSelect}
              name={`${prefix}.data.group`}
              isClearable
              loadOptions={(inputValue: string) =>
                client<Json<AADGroups>>("json")(
                  `/api/self-service/aad/groups?query=${inputValue}`
                )
              }
              getOption={(x) => x}
              getOptionLabel={(x) => x.name ?? ""}
              getOptionValue={(x) => x.id ?? ""}
              getFormValue={(x) => x}
            />
          );
        },
      },
      steveSandbox: {
        name: "Create AWS sandbox",
        schema: yup.object({}),
        Controls() {
          return null;
        },
      },
      steveAccount: {
        name: "Create AWS account",
        schema: yup.object({
          ou: yup.string().required("This is a required field"),
        }),
        Controls({ prefix }) {
          return <FormGroup label="AWS OU" name={`${prefix}.data.ou`} />;
        },
      },
    },
  },
  email: {
    name: "Email",
    schema: yup.object({
      to: yup.string().required("This is a required field"),
      from: yup.string().required("This is a required field"),
      subject: yup.string().required("This is a required field"),
      text: yup.string().required("This is a required field"),
    }),
    Controls() {
      return (
        <>
          <FormGroup label="To" name="grantor.to" />
          <FormGroup label="From" name="grantor.from" />
          <FormGroup label="Subject" name="grantor.subject" />
          <FormGroup
            label="Text (include {{requestor}} for the requestor's email)"
            name="grantor.text"
            as={FormTextArea}
          />
        </>
      );
    },
    defaults: {
      to: "",
      from: "",
      subject: "",
      text: "",
    },
    actions: {},
  },
};

export default grantors;
