import * as yup from "yup";
import grantors from "./client-grantors";
import { ObjectSchema } from "yup";
import { flattenParams } from "../utils";
import { Json } from "../api-types";
import type Application from "../server/models/Application";
import type Param from "../server/models/Param";
import type OptionData from "../server/models/OptionData";

const paramSchema = (
  app: Json<Omit<Application, "id">>
): yup.ObjectSchema<Json<Param>> =>
  yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required("Name is required"),
    options: yup.array().of(
      yup.lazy((option) =>
        yup.object().shape({
          id: yup.string().required(),
          name: yup.string().required("Name is required"),
          params: yup.array().of(yup.lazy(() => paramSchema(app))),
          actions: yup.array().of(yup.string().required()),
          data: option.actions?.reduce(
            (acc: ObjectSchema<Json<OptionData>>, value: string) =>
              app.grantor?.type && grantors[app.grantor.type]
                ? acc.concat(grantors[app.grantor.type].actions[value].schema)
                : acc,
            yup.object()
          ),
        })
      )
    ),
    text: yup.string().nullable(),
    required: yup.boolean().required(),
    freeForm: yup.boolean().required(),
    freeFormType: yup.string().required(),
  });

export const appSchema = yup.lazy(
  (value): yup.ObjectSchema<Json<Omit<Application, "id">>> =>
    yup.object().shape({
      name: yup.string().required("Name is required"),
      dataStewards: yup.array().of(yup.object()),
      grantor: grantors[value.grantor?.type]?.schema
        .shape({
          type: yup.string().required(),
        })
        .nullable(),
      params: yup.array().of(yup.lazy(() => paramSchema(value))),
      url: yup.string().nullable(),
      watchers: yup.array().of(yup.object()),
      accessRules: yup.array().of(
        yup.object({
          attribute: yup.string().required(),
          type: yup.string().required(),
          value: yup.array().of(yup.string().required()).required(),
        })
      ),
    })
);

export const paramValuesSchema = (
  params: Json<Param>[] | undefined,
  values: { [key: string]: string[] | undefined }
): yup.ObjectSchema<{ [key: string]: string[] | undefined }> =>
  yup.object().shape(
    Object.fromEntries(
      Array.from(flattenParams(params, values), ([id, param]) => [
        id,
        param.required
          ? yup
              .array()
              .of(yup.string().required("This is a required field"))
              .min(1, "This is a required field")
              .required(
                param.freeForm
                  ? { 0: "This is a required field" }
                  : "This is a required field"
              )
          : yup.array().of(yup.string().required()),
      ])
    )
  );
